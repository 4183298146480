export var endpoints = {
    main: {
        url: '/'
    },
    mainAdmin: {
        url: '/admin'
    },
    mainExpert: {
        url: '/expert'
    },
    mainRedactor: {
        url: '/redactor'
    },
    mainEmployee: {
        url: '/employee'
    },
    adminClients: {
        url: '/admin/clients'
    },
    adminTestingTasks: {
        url: '/admin/testing-tasks'
    },
    redactorQuestions: {
        url: '/redactor/questions'
    },
    redactorProfile: {
        url: '/redactor/profile'
    },
    redactorDirections: {
        url: '/redactor/directions'
    },
    redactorSources: {
        url: '/redactor/sources'
    },
    redactorKnowledges: {
        url: '/redactor/knowledges'
    },
    redactorGeneration: {
        url: '/redactor/generation'
    },
    expertQuestions: {
        url: '/expert/questions'
    },
    expertProfile: {
        url: '/expert/profile'
    },
    adminProfile: {
        url: '/admin/profile'
    },
    adminRequestedFunctionalDirections: {
        url: '/admin/clients/:clientId/directions',
        name: 'directions'
    },
    adminClient: {
        url: '/admin/clients/:clientId',
        name: 'client'
    },
    adminExperts: {
        url: '/admin/experts'
    },
    adminRedactors: {
        url: '/admin/redactors'
    },
    adminResults: {
        url: '/admin/clients/:clientId/results',
        name: 'results'
    },
    adminResultsReport: {
        url: '/admin/clients/:clientId/results/report',
        name: 'results-report'
    },
    adminResultsAssign: {
        url: '/admin/clients/:clientId/results/assign',
        name: 'results-assign'
    },
    adminResult: {
        url: '/admin/clients/:clientId/results/:employeeCode/:testId',
        name: 'redactor'
    },
    knowledgeBase: {
        url: '/admin/knowledge'
    },
    generation: {
        url: '/admin/generation',
        name: 'generation'
    },
    clientDirections: {
        url: '/admin/directions',
        name: 'directions'
    },
    clientKnowledgeFields: {
        url: '/admin/directions/:directionId/knowledge-fields',
        name: 'knowledge-fields'
    },
    clientNews: {
        url: '/admin/directions/:directionId/knowledge-fields/:knowledgeFieldsId'
    },
    adminDirections: {
        url: '/admin/knowledge/directions'
    },
    adminAllKnowledgeFields: {
        url: '/admin/knowledge/knowledges',
        name: 'knowledge-fields'
    },
    adminKnowledgeFields: {
        url: '/admin/knowledge/directions/:directionId/knowledge-fields',
        name: 'knowledge-fields'
    },
    adminNews: {
        url: '/admin/knowledge/directions/:directionId/knowledge-fields/:knowledgeFieldsId'
    },
    adminClientEmployees: {
        url: '/admin/clients/:clientId/employees',
        name: 'employees'
    },
    adminClientEmployeeReportPreview: {
        url: '/admin/clients/:clientId/employees/:employeeId',
        name: 'employee-report-preview'
    },
    adminStatisctics: {
        url: '/admin/clients/:clientId/statisctics',
        name: 'statisctics'
    },
    clientStatisctics: {
        url: '/admin/statisctics',
        name: 'statisctics'
    },
    adminReports: {
        url: '/admin/clients/:clientId/reports',
        name: 'reports'
    },
    clientReports: {
        url: '/admin/reports',
        name: 'reports'
    },
    clientReport: {
        url: '/admin/reports/:reportId',
        name: 'reports'
    },
    adminReport: {
        url: '/admin/clients/:clientId/reports/:reportId',
        name: 'report'
    },
    adminClientQuestionsEmployees: {
        url: '/admin/clients/:clientId/questions',
        name: 'questions'
    },
    adminClientResults: {
        url: '/admin/results',
        name: 'results'
    },
    adminClientResultsAssign: {
        url: '/admin/results/assign',
        name: 'results-assign'
    },
    adminClientEmployeeResult: {
        url: '/admin/results/:employeeCode/:testId'
    },
    adminClientResultsFromKnowledges: {
        url: '/admin/directions/:directionId/knowledge-fields/:knowledgeFieldsId/results',
        name: 'results'
    },
    adminClientResult: {
        url: '/admin/directions/:directionId/knowledge-fields/:knowledgeFieldsId/results/:employeeCode/:testId',
        name: 'redactor'
    },
    adminSources: {
        url: '/admin/knowledge/sources',
        name: 'sources'
    },
    clientSources: {
        url: '/admin/directions/:directionId/knowledge-fields/:knowledgeFieldsId/sources',
        name: 'sources'
    },
    clientEmployees: {
        url: '/admin/employees',
        name: 'employees'
    },
    clientQuestions: {
        url: '/admin/directions/:directionId/knowledge-fields/:knowledgeFieldsId/questions',
        name: 'questions'
    },
    adminQuestions: {
        url: '/admin/knowledge/questions',
        name: 'questions'
    },
    // Employee
    employeeAssignedTesting: {
        url: '/employee/:employeeCode/test',
        name: 'employee'
    },
    employeeAssignedTestingResult: {
        url: '/employee/:employeeCode/test/:testId/result',
        name: 'result'
    },
    employeeAssignmentResults: {
        url: '/employee/:employeeCode/assignment-results',
        name: 'employeeAssignmentResults'
    },
    employeeAssignedTest: {
        url: '/employee/:employeeCode/test/:testId',
        name: 'test'
    },
    // Manager routes
    employeeTeam: {
        url: '/employee/:employeeCode/team'
    },
    employeeTeamReports: {
        url: '/employee/:employeeCode/team/reports'
    },
    // === manager routes end
    functions: {
        url: '/functions'
    },
    sysAdminInstruction: {
        url: '/sys-admin-instruction'
    },
    userGuide: {
        url: '/user_guide'
    },
    oferta: {
        url: '/oferta'
    }
};
