var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
import { RounedCheck as _RounedCheck } from 'components/UI/atoms';
import { Fonts } from 'types/TextProps';
import { COLOR, MEDIA, TEXT } from 'uikit/vars';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n\n  border: 1px solid ", ";\n  border-radius: 20px;\n\n  @media (min-width: ", ") {\n    overflow: hidden;\n    display: grid;\n    grid-template-columns: 270px 3fr;\n\n    min-height: 833px;\n    white-space: break-spaces;\n  }\n"])), COLORS.PURPLE_400, MEDIA.DESKTOP_1);
export var QuestionsNumbers = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-rows: max-content;\n  grid-gap: 12px;\n\n  padding: 20px;\n\n  background-color: ", ";\n\n  @media (min-width: ", ") {\n    grid-gap: 24px;\n\n    padding: 40px 40px 195px 40px;\n  }\n"])), COLORS.PURPLE_600, MEDIA.DESKTOP_1);
export var TimeWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 7px;\n  grid-template-columns: 40px 1fr;\n  align-items: center;\n"])));
export var TimeIconWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 40px;\n  height: 40px;\n\n  background-color: ", ";\n  border-radius: 12px;\n"])), COLORS.PURPLE_100);
export var TimeText = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 17px;\n  color: #546e7a;\n"])), Fonts.NotoSans);
export var Title = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 600;\n  font-style: normal;\n  line-height: 24px;\n  color: #263238;\n"])), Fonts.Montserrat);
export var QuestionsNumbersWrapper = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  overflow-y: auto;\n  display: flex;\n  gap: 10px;\n  flex-wrap: wrap;\n\n  &::-webkit-scrollbar {\n    width: 8px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: ", ";\n    border-radius: 4px;\n  }\n\n  @media (min-width: ", ") {\n    display: grid;\n    grid-auto-rows: max-content;\n    grid-gap: 10px;\n    grid-template-columns: 40px 40px 40px 40px;\n    height: 390px;\n    margin-right: -35px;\n  }\n"])), COLORS.LOGAN, MEDIA.DESKTOP_1);
export var Line = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  height: 1px;\n  margin-right: -40px;\n  margin-left: -40px;\n  background-color: ", ";\n"])), COLORS.PURPLE_400);
export var Question = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  cursor: ", ";\n\n  position: relative;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  min-width: 40px;\n  height: 40px;\n\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n\n  background: ", ";\n  border-radius: 12px;\n"])), function (_ref) {
    var isCurrent = _ref.isCurrent, isPassed = _ref.isPassed;
    return (isCurrent !== null && isCurrent !== void 0 ? isCurrent : isPassed) ? 'default' : 'pointer';
}, Fonts.NotoSans, function (_ref2) {
    var isCurrent = _ref2.isCurrent, isPassed = _ref2.isPassed;
    if (isCurrent) {
        return COLORS.CARD_BG;
    }
    if (isPassed) {
        return COLORS.WILD_BLUE_YONDER;
    }
    return COLORS.PURPLE_100;
}, function (_ref3) {
    var isCurrent = _ref3.isCurrent;
    return isCurrent ? COLORS.PURPLE_100 : COLORS.WHITE;
});
export var QuestionWrapper = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-rows: max-content;\n  grid-gap: 12px;\n  padding: 20px;\n\n  @media (min-width: ", ") {\n    grid-gap: 24px;\n    padding: 40px 40px 40px 40px;\n  }\n"])), MEDIA.DESKTOP_1);
export var QuestionInner = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n  }\n"])), MEDIA.TABLET_1);
export var QuestionHeader = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 24px;\n  color: #263238;\n\n  span {\n    color: ", ";\n  }\n"])), Fonts.Montserrat, COLORS.PURPLE_100);
export var QuestionText = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  margin-top: 12px;\n\n  ", ";\n  color: var(", ");\n"])), TEXT.LARGE1, COLOR.DARK_PURPLE_100);
export var ButtonsWrapper = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  align-items: center;\n  justify-content: space-between;\n\n  button {\n    width: 100%;\n  }\n\n  @media (min-width: ", ") {\n    display: grid;\n    grid-gap: 20px;\n    grid-template-columns: 20% 20%;\n  }\n"])), MEDIA.TABLET_1);
export var RounedCheck = styled(_RounedCheck)(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  right: 0;\n"])));
export var ModalText = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  ", ";\n  color: var(", ");\n\n  span {\n    color: var(", ");\n  }\n"])), TEXT.MEDIUM1, COLOR.DARK_PURPLE_100, COLOR.PURPLE_100);
