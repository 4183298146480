function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) {
    var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1;
    try {
        if (_x = (_i = _i.call(arr)).next, 0 === i) {
            if (Object(_i) !== _i)
                return;
            _n = !1;
        }
        else
            for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0)
                ;
    }
    catch (err) {
        _d = !0, _e = err;
    }
    finally {
        try {
            if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r))
                return;
        }
        finally {
            if (_d)
                throw _e;
        }
    }
    return _arr;
} }
function _arrayWithHoles(arr) { if (Array.isArray(arr))
    return arr; }
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { managerApiSlice } from 'modules';
import { parseUrlParam } from 'utils/urlUtils';
import { FILTER } from '../../../constants';
export var useFilters = function useFilters() {
    var _useSearchParams = useSearchParams(), _useSearchParams2 = _slicedToArray(_useSearchParams, 1), searchParams = _useSearchParams2[0];
    var funcDirectionValue = parseUrlParam({
        value: searchParams.get(FILTER.functionalDirectionId)
    });
    var _managerApiSlice$useG = managerApiSlice.useGetFunctionalDirectionsQuery(), funcDirectionsData = _managerApiSlice$useG.data;
    var _managerApiSlice$useG2 = managerApiSlice.useGetKnowledgeFieldsByFuncDirectionQuery({
        functionalDirectionId: funcDirectionValue
    }, {
        skip: !funcDirectionValue
    }), knowledgeFields = _managerApiSlice$useG2.data;
    var onChangeFuncDirection = React.useCallback(function (newValue) {
        searchParams.set(FILTER.functionalDirectionId, newValue);
    }, [searchParams]);
    var onChangeKnowledgeField = React.useCallback(function (newValue) {
        searchParams.set(FILTER.knowledgeFieldId, newValue);
    }, [searchParams]);
    var onChangeFuncDirectionSelect = React.useCallback(function (newValue) {
        onChangeFuncDirection(newValue);
        onChangeKnowledgeField('');
    }, [onChangeFuncDirection, onChangeKnowledgeField]);
    var funcDirectionsOptions = React.useMemo(function () {
        if (!(funcDirectionsData !== null && funcDirectionsData !== void 0 && funcDirectionsData.data))
            return [];
        return funcDirectionsData === null || funcDirectionsData === void 0 ? void 0 : funcDirectionsData.data.map(function (funcDirection) {
            return {
                value: funcDirection.id,
                title: funcDirection.title
            };
        });
    }, [funcDirectionsData]);
    var knowledgeFieldsOptions = React.useMemo(function () {
        if (!knowledgeFields)
            return [];
        return knowledgeFields.map(function (knowledgeField) {
            return {
                value: knowledgeField.id,
                title: knowledgeField.title
            };
        });
    }, [knowledgeFields]);
    return {
        funcDirectionValue: funcDirectionValue,
        funcDirectionsOptions: funcDirectionsOptions,
        onChangeFuncDirectionSelect: onChangeFuncDirectionSelect,
        knowledgeFieldsOptions: knowledgeFieldsOptions
    };
};
