import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (function () {
    for (var _len = arguments.length, refs = new Array(_len), _key = 0; _key < _len; _key++) {
        refs[_key] = arguments[_key];
    }
    var targetRef = React.useRef();
    React.useEffect(function () {
        refs.forEach(function (ref) {
            if (!ref)
                return;
            if (typeof ref === 'function') {
                ref(targetRef.current);
            }
            else {
                // eslint-disable-next-line no-param-reassign
                ref.current = targetRef.current;
            }
        });
    }, [refs]);
    return targetRef;
});
