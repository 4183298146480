var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var UploadArea = styled.label(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 86px;\n  padding: 33px 14px 33px 12px;\n\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n\n  background-color: ", ";\n  border: ", ";\n  border-radius: 5px;\n\n  ", ";\n\n  & > div {\n    ", "\n  }\n\n  & > div > span {\n    cursor: pointer;\n    color: ", ";\n    text-decoration-line: underline;\n  }\n"])), Fonts.NotoSans, COLORS.MAGNOLIA, function (_ref) {
    var isFilename = _ref.isFilename;
    return isFilename ? "1px solid ".concat(COLORS.PURPLE_100) : "1px dashed ".concat(COLORS.PURPLE_100);
}, function (_ref2) {
    var isFilename = _ref2.isFilename;
    return isFilename ? "display: grid;\n  grid-template-columns: 1fr 20px;\n  align-items: center;\n  grid-gap: 20px;" : null;
}, function (_ref3) {
    var isFilename = _ref3.isFilename;
    return isFilename ? "font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  color: ".concat(COLORS.PURPLE_100, ";\n  text-decoration-line: underline;") : "text-align: center;\n    color: ".concat(COLORS.DARK_PURPLE_100, ";");
}, COLORS.PURPLE_100);
export var IconWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  justify-self: end;\n  & img {\n    cursor: pointer;\n    display: block;\n  }\n"])));
